













































































import Lesson from "@/interfaces/Lesson";
import { ActiveProject } from "@/interfaces/Project";
import { userModule } from "@/store/modules/user";
import { computed, defineComponent, inject, PropType, reactive, ref } from "@vue/composition-api";
import { CalendarEvent } from "vuetify";

export default defineComponent({
  name: "LessonCalendar",
  props: {
    lessons: {
      type: Array as PropType<Lesson[]>,
      default: () => [],
    },
    projects: {
      type: Array as PropType<ActiveProject[]>,
      required: true
    },
  },
  setup(props) {
    const vm = reactive({
      focus: "",
      selectedOpen: false,
      events: [] as CalendarEvent[],
      currentLesson: {} as Lesson,
      showLessonCardDialog: false,
      type: "month",
    });
    const colors = ["blue", "indigo", "deep-purple", "cyan", "green", "orange", "grey darken-1", "red", "teal", "purple", "pink", "yellow"];
    const calendar = ref();
    const calenderLessons = computed(() => {
      return props.lessons.map(lesson => {
        return {
          name: getLessonName(lesson),
          start: lesson.startTime * 1000,
          end: lesson.endTime * 1000,
          timed: true,
          color: lesson.customerUID[1] ? colors[lesson.customerUID[1].charCodeAt(0) % 12] : "blue",
          ID: lesson.ID,
        } as CalendarEvent;
      });
    });

    const isSeller = computed(() => userModule.state.user.seller);

    function getLessonName(lesson: Lesson): string {
      if (userModule.state.user.seller) {
        return lesson.project?.studentName || `${lesson.customer?.profile.firstName} ${lesson.customer?.profile.lastName}`;
      }
      else if (lesson.seller) {
        return lesson.seller?.profile.firstName;
      }
      return "";
    }

    const showLessonCard = inject<(lesson: Lesson) => void>("showLessonCard");

    function showEvent(event: CalendarEvent) {
      if (props.lessons.filter(lesson => lesson.ID === event.event.ID)[0]) {
        if (showLessonCard) showLessonCard(props.lessons.filter(lesson => lesson.ID === event.event.ID)[0]);
      }
    }

    function viewDay({ date }: Record<"date", string>) {
      vm.focus = date;
      vm.type = "day";
    }

    function prev() {
      calendar.value.prev();
    }

    function next() {
      calendar.value.next();
    }

    return {
      calenderLessons,
      calendar,
      isSeller,
      next,
      prev,
      showEvent,
      viewDay,
      vm,
    };
  },
});
